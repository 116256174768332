import { RiTeamFill } from "react-icons/ri";
import { IoIosApps } from "react-icons/io";
import { TbWorld, TbLanguage } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Head = () => {
    const { t } = useTranslation();

    return (
        <div className="header">
            <Link to="/">
                <img className="logo" src="/images/starw.png" />
            </Link>
            <div className="menu">
                <Link to="products" className="item">
                    <span>Products</span>
                </Link>
                <Link to="investor" className="item">
                    <span>Investments</span>
                </Link>
                <Link to="contact" className="item">
                    <span>Contact</span>
                </Link>
                <Link to="/language" className="item">
                    <TbLanguage className="icon" />
                    <span>{t('language')}</span>
                </Link>
            </div>
        </div >
    )
}