import { useTranslation, withTranslation } from 'react-i18next';

export const Investor = () => {
    const { t } = useTranslation();

    return (
        <div className="investor page">
            <span>Supersky at the right point in industry.</span>
        </div>
    )
}