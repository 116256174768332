export const Contact = () => {

    return (
        <div className="page-contact page">
            <span>You can reach us via the form below or by e-mail.</span>
            <span>support@supersky.app</span>
            <span className="headline">Contact Us</span>
            <input type="text" placeholder="Email" />
            <textarea type="text" placeholder="Description" />
            <span className="button">Send</span>
        </div>
    )
}