import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Apps, Products } from "../Pages/Products";
import { Contact } from "../Pages/Contact";
import { Head } from "../Pages/Header";
import { Home } from "../Pages/Home"
import { Investor } from "../Pages/Investments";
import { Language } from "../Pages/Language";
import { NotFound } from "../Pages/NotFound";
import { Privacy } from "../Pages/Privacy";
import { TermsOfUse } from "../Pages/TermsOfUse";
import { Footer } from "../Pages/Footer";

export const PageRoutes = () => {
    return (
        <BrowserRouter>
            <Head />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="products" element={<Products />} />
                <Route path="investor" element={<Investor />} />
                <Route path="contact" element={<Contact />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<TermsOfUse />} />
                <Route path="language" element={<Language />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}