import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { PageRoutes } from './Utils/PageRoutes';
import "./i18nextConf";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PageRoutes />
);