export const TermsOfUse = () => {
    return (
        <div className="privacy page">
            <h2>TERMS OF USE</h2>
            These terms will automatically apply to you by downloading or using the application, so you should make sure to read them carefully before using the application. You are not allowed to copy or modify the application, any part of the application in any way. You are not allowed to try to extract the source code of the application, and you must also not try to translate the application into other languages or create derivative versions. The application itself and all trademarks, copyright, database rights and other intellectual property rights related to it belong to us.
            <br /><br />
            We are committed to making the Supersky app as useful and efficient as possible. Therefore, we reserve the right to make changes to the app or charge for its services at any time and for any reason. We will never charge you for apps or services without making it clear to you exactly what you're paying for.
            <br /><br />
            The Supersky app stores and processes personal data that you have provided to us, in order to provide my Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Supersky app won’t work properly or at all.
            <br /><br />
            Supersky requires the application to have an active internet connection. The connection may be Wi-Fi or provided by your mobile network provider, but if you do not have Wi-Fi access and do not have any, Supersky cannot be held responsible for the application not working at full functionality.
            <br /><br />
            If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.
            <br /><br />
            Likewise, we cannot always take responsibility for the way you use the app, so you need to make sure your device stays charged – Erman Gündogar cannot accept responsibility if the battery dies and you cannot open it to use the Service.
            <br /><br />
            Along the same lines, Supersky cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, Erman Gundogar cannot accept responsibility.
            <br /><br />
            With respect to Supersky responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Supersky accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
            <br /><br />
            At some point, we may want to update the app. Requirements may change and you must download updates if you want to continue using them. It does not promise to always update the app to be relevant to you and/or to work with the version of iOS you have installed on your device. However, you promise to always accept app updates when offered to you, we may also wish to stop providing the app and may terminate the app at any time without notice to you of termination. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will terminate; (b) you must stop using the application and (if necessary) delete it from your device.
            <br /><br />
            Changes to This Terms and Conditions
            I may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.
            <br /><br />
            Contact Us
            If you have any questions or suggestions about my Terms and Conditions, do not hesitate to contact me at support@supersky.app.
        </div>
    )
}