import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web'

export const Home = () => {
    const { t } = useTranslation();
    const FadeIn = ({ isVisible, children, className }) => {
        const styles = useSpring({
            from: {
                scale: 0,
                opacity: 0
            },
            to: {
                scale: 1,
                opacity: 1
            }
        })

        return <animated.div className={className} style={styles}>{children}</animated.div>
    }
    return (
        <div className="home page">
            <div className='onboard'>
                <img className='bg' src="/images/onboardb.png" />
                <FadeIn isVisible={true} children={<span className='title'>Developing World's Leading Apps</span>
                } />
                <FadeIn className="cap" isVisible={true} children={
                    <span className='caption'>We develop simple and understandable applications for our users, rebuilding the industry with new technologies.</span>
                } />
            </div>
            <div className="slider">
                <div className="item">
                    <img src="/images/s1.png" />
                    <div className="details">
                        <h1>{t("hi")}NEXT GENERATION APPS</h1>
                        <h3>We develop simple and understandable applications for our users, rebuilding the industry with new technologies.</h3>
                    </div>
                </div>
                <div className="item">
                    <h1>DEVELOPING THE APP INDUSTRY</h1>
                    <h3>We are rebuilding the industry with new technologies.</h3>
                </div>
            </div>
            <div className="projects">
                <div className="item">
                    <span>ASO Tool</span>
                    <span className="alt">ASO Tool</span>
                </div>
            </div>
        </div>
    )
}