import { useTranslation } from "react-i18next";

export const Language = () => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    const languages = [
        { languageCode: "ar", languageName: "عربي" },
        { languageCode: "de", languageName: "Deutsch" },
        { languageCode: "en", languageName: "English" },
        { languageCode: "fr", languageName: "French" },
        { languageCode: "hi", languageName: "हिन्दी" },
        { languageCode: "tr", languageName: "Türkçe" }
    ]
    return (
        <div className="language page">
            <span className="headline">{t("languages")}</span>
            <div className="languages">
                {languages.map((obj) => {
                    return <span className={`item${i18n.language == obj.languageCode ? " active" : ""}`} onClick={() => changeLanguage(obj.languageCode)}>{obj.languageName}</span>
                })}
            </div>
        </div >
    )
}